.ressource-page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  padding-top: 200px;
  margin-bottom: 120px;
  gap: 60px;

  @include font-body();

  @include sm {
    flex-direction: column;
    padding-top: 60px;
  }
}

.ressource-cover {
  width: 100%;
}

.ressource-cover img {
  width: 100%;
}

.ressource-details {
  padding: 0;
  margin: 0;
}

.ressource-details > .subtitle {
  margin-top: 40px;
  font-weight: 600;

  @include sm {
    font-size: 0.8em;
  }
}

.ressource-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.ressource-list-item img {
  width: 24px;
  aspect-ratio: 1/1;
}

.ressource-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 40px 0;
}

.ressource-link {
  display: flex;
}

.ressource-previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 40px;
}

.ressource-preview-image {
  width: 100px;
}

.ressource-preview-image img {
  width: 100%;
  aspect-ratio: 1/1;
}
