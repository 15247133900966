.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #999;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.spinner-overlay {
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity */
  display: grid;
  place-items: center;
}

.spinner-overlay-active {
  visibility: visible;
}

.spinner-overlay-inactive {
  visibility: hidden;
}

.spinner-content {
  display: grid;
  place-items: center;
}

.spinner-overlay-text {
  @include font-body();
  color: #fff;
  margin-top: 20px;
}
