.page-header {
  z-index: 10;
  position: fixed;
  width: 100%;
}

.desktop-nav {
  width: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  height: 8rem;
  -webkit-transition: background-color 0.8s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    padding 0.4s ease-in-out, height 0.4s ease-in-out,
    -webkit-box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    -webkit-transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s;
  transition: background-color 0.8s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    padding 0.4s ease-in-out, height 0.4s ease-in-out,
    -webkit-box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    -webkit-transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s;
  -o-transition: box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    background-color 0.8s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    padding ease-in-out 0.4s, height ease-in-out 0.4s,
    transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s;
  transition: box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    background-color 0.8s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    padding 0.4s ease-in-out, height 0.4s ease-in-out,
    transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s;
  transition: box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    background-color 0.8s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    padding 0.4s ease-in-out, height 0.4s ease-in-out,
    transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s,
    -webkit-box-shadow 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94),
    -webkit-transform 0.4s cubic-bezier(0.36, 0.64, 0.23, 0.94) 0.2s;

  @include sm {
    display: none;
    visibility: hidden;
  }
}

.is-scrolled .desktop-nav {
  background-color: white;
  height: 5rem;
  -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
}

.container {
  margin: 0 auto 0 auto;
  max-width: 1440px;

  @include lg {
    max-width: 1024px;
  }

  @include md {
    max-width: 768px;
  }

  @include sm {
    max-width: 100%;
    overflow-y: hidden;
    padding: 0 1.2rem 0 1.2rem;
  }
}

.container-full-width {
  width: 100%;
  margin: 0;
  padding: 0;

  @include sm {
    max-width: 100%;
    overflow-y: hidden;
    padding: 0;
  }
}

.nav-container {
  height: 100%;
}

.nav-wrapper {
  height: 100%;
  display: flex;
}

.nav-logo {
  height: 100%;
  width: 200px;
  display: flex;
}

.logo {
  margin: auto 0 auto 0;
}

.is-scrolled .nav-logo {
  height: 100%;
}

.front-menu-wrapper {
  display: flex;
  margin-left: auto;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  transition: transform 0.3s ease;
}

.front-menu {
  justify-content: flex-end;
  flex-wrap: wrap;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.front-menu-item {
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 3rem;
  text-align: -webkit-match-parent;
  @include font-body();
}

.front-menu a {
  text-decoration: none;
}

.front-menu a:hover {
  color: var(--primary-color);
}

.front-menu-item {
  @include text-main();
}

.front-menu-item-logo {
  width: 22px;
  margin-right: 10px;
}

.is-scrolled .front-menu-item {
  height: 100%;
}

.mobile-header {
  background-color: white;
  position: fixed;
  z-index: 110;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;

  @include gt-sm {
    display: none;
    visibility: hidden;
  }
}

.mobile-header-nav {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem 1.2rem 1rem 1.2rem;
}

.mobile-header-nav-toggle {
  display: flex;
}

.mobile-toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.mobile-toggle-button > span {
  display: block;
  width: 1.6rem;
  height: 0.12rem;
  --bg-opacity: 1;
  background-color: #525252;
  background-color: rgba(80, 65, 57, var(--bg-opacity));
  opacity: 1;
  transition: opacity 0.3s ease,
    transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: 0.4rem;
}

.mobile-toggle-button > span:last-child {
  margin-bottom: 0;
}

.mobile-menu-active .mobile-toggle-button > span:first-child {
  transform: rotate(45deg) translate(6px, 6px);
}

.mobile-menu-active .mobile-toggle-button > span:nth-child(2) {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: left center;
}

.mobile-menu-active .mobile-toggle-button > span:last-child {
  transform: rotate(-45deg) translate(6px, -6px);
}

.mobile-header-logo {
  display: flex;
}

.mobile-logo {
  margin: auto 0 auto 0;
}

.mobile-modal-menu {
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  padding-top: 0;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition: 0.4s cubic-bezier(1, 0, 0, 1);
  -o-transition: 0.4s cubic-bezier(1, 0, 0, 1);
  transition: 0.4s cubic-bezier(1, 0, 0, 1);
}

.is-visible {
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mobile-modal-navigation {
  opacity: 0;
  -webkit-transform: translateY(1rem);
  -ms-transform: translateY(1rem);
  transform: translateY(1rem);
  -webkit-transition: 0.3s ease-out 0.4s;
  -o-transition: 0.3s ease-out 0.4s;
  transition: 0.3s ease-out 0.4s;

  width: 100%;

  padding: 8rem 1.5rem 1.5rem 1.5rem;
}

.is-visible .mobile-modal-navigation {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mobile-modal-navigation a {
  text-decoration: none;
  @include font-body();
}

.mobile-modal-login {
  @include font-body();
  display: grid;
  grid-gap: 2rem;
  padding: 3rem 1.5rem 3rem 1.5rem;
  margin: 0 1.5rem 1.5rem 1.5rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
}

.mobile-menu-item {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: 0;
  text-align: -webkit-match-parent;
  @include font-body();
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

.mobile-menu a {
  text-decoration: none;
}

.mobile-menu a:hover {
  color: var(--primary-color);
}

.mobile-menu-item {
  @include text-main();
}

.mobile-menu-item-logo {
  width: 32px;
  margin-right: 10px;
}

.front-hero {
  background-color: var(--bg-color);
  padding: 250px 250px 0 250px;
  text-align: center;
  margin-bottom: 100px;

  @include sm {
    padding: 0 1.2rem 0 1.2rem;
    margin-top: 180px;
  }
}

.front-hero h1 {
  font-size: 5em;
  line-height: 1.2em;
  font-weight: bold;

  @include sm {
    font-size: 2.4em;
  }
}

.front-hero-subtitle {
  @include font-medium();
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 1.4em;

  @include sm {
    font-size: 1.2em;
  }
}

.front-social-proof {
  height: 100vh;
  background-color: aquamarine;
}

.front-examples {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @include gt-sm {
    flex-wrap: nowrap;
  }

  @include sm {
    flex-wrap: wrap;
  }
}

.front-example {
  background-color: #ddd;
  overflow: hidden;

  @include gt-sm {
    flex: 1 1 25%;
    aspect-ratio: 1/1;
  }

  @include sm {
    flex: 1 1 40%;
    aspect-ratio: 1/1;
  }
}

.front-example img {
  width: 100%;
  object-fit: cover;
}

footer {
  background-color: var(--footer-bg-color);
  @include font-body();
  color: var(--footer-font-color);
  min-height: 800px;
}

#how {
  scroll-margin-top: 10rem;
}

.front-how-title {
  text-align: center;
  margin-top: 160px;
  margin-bottom: 80px;
  font-size: 4em;

  @include sm {
    font-size: 2.4em;
    margin-top: 100px;
  }
}

.front-how {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 164px;

  @include sm {
    flex-wrap: wrap;
    gap: 100px;
    flex-direction: column;
  }
}

.front-how-box {
  flex: 1 1 33.33333%;
  @include font-body();
  font-size: 1.3em;
  text-align: center;

  @include sm {
    font-size: 1.2em;
    padding: 0 30px 0 30px;
  }
}

.front-how-box-icon {
  width: 108px;
  height: 108px;
  margin: 0 auto 32px auto;

  @include sm {
    width: 72px;
    aspect-ratio: 1/1;
    margin-bottom: 0;
  }
}

.front-services {
  background-color: #f4f4f4;
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  @include font-body();
  font-size: 1.4em;
  text-align: center;

  @include sm {
    display: none;
    visibility: hidden;
  }
}

.front-services h2 {
  margin-bottom: 40px;
  font-size: 3em;
}

.front-services-list {
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  gap: 24px;
}

.front-service {
  background-color: white;
  flex: 1 1 33.33333%;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.14) 0 1px 2px 0, rgba(0, 0, 0, 0.14) 0 2px 4px 0;
  border-radius: 4px;
  font-size: 0.9em;

  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  img {
    width: 24px;
  }
}

.front-services .subtitle {
  margin-bottom: 32px;
}

#pricing {
  scroll-margin-top: 10rem;
}

.front-plan-title {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 4em;

  @include sm {
    font-size: 2.4em;
  }
}

.front-plan {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 80px;

  @include sm {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.front-plan-box {
  @include font-body();
  background-color: white;
  border-radius: 14px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.14) 0 1px 2px 0, rgba(0, 0, 0, 0.14) 0 2px 4px 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 22px;

  text-align: left;
  justify-content: space-between;

  padding: 72px 44px 72px 44px;

  @include sm {
    width: auto;
    padding: 50px 32px 50px 32px;
  }
}

.front-plan-box h3 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 32px;

  @include sm {
    font-size: 2rem;
    padding-bottom: 20px;
  }
}

.front-plan-list-item {
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @include sm {
    font-size: 1.1rem;
  }
}

.front-plan-list-item img {
  width: 32px;
  height: 32px;

  @include sm {
    width: 24px;
    aspect-ratio: 1/1;
  }
}

.front-plan-bottom {
  border-top: 1px solid #e3e3e3;
  padding-top: 32px;
}

.front-plan-price {
  font-size: 1.8rem;
  font-weight: 600;
}

.front-plan-price-small {
  font-size: 1.2rem;
  font-weight: 600;
}

.front-plan-button {
  display: flex;
  margin-top: 16px;
}

.front-profile {
  @include font-body();
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 0px;
  padding: 0 200px 0 200px;
  margin-bottom: 100px;

  @include sm {
    flex-direction: column;
    padding: 0;
  }
}

.front-profile > * {
  width: 40%;

  @include sm {
    width: auto;
  }
}

.front-profile-portrait {
  width: 100%;
  aspect-ratio: 1/1;
  display: hidden;

  @include sm {
    width: 30%;
    margin-bottom: 10px;
  }
}

.front-profile-text {
  margin: auto;
}

.front-profile-text-cols {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  margin-top: 80px;
}

.front-profile-text-col img {
  width: 32px;
  height: 32px;
}

.footer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 24px;
  padding-top: 80px;

  @include sm {
    flex-direction: column;
  }
}

.footer-col {
  @include gt-sm {
    flex: 1 1 12%;
  }
}

.footer > * {
  @include sm {
    margin-bottom: 40px;
  }
}

.footer-logo {
  padding-right: 40px;
}

.footer-spacer {
  flex-grow: 1;
}

.footer a {
  text-decoration: none;
  color: #fff;
}

.footer-location {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
}

.footer-location .left {
  width: 40%;
}

.footer-location .right {
  width: 60%;
}

.footer-location img {
  height: 100px;
}

.text-grey {
  color: #d1d1d1;
}
