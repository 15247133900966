// https://github.com/Necromancerx/media-queries-scss-mixins
// media aliases and breakpoints
$screen-sm-min: 320px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1440px;

$screen-xs-max: 319px;
$screen-sm-max: 767px;
$screen-md-max: 1023px;
$screen-lg-max: 1439px;
$screen-xl-max: 5000px;

// media devices
@mixin xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media lt queries
@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
