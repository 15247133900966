.login {
  display: grid;
  grid-template-columns: 50% 50%;

  min-height: 100vh;

  @include font-body();

  @include sm {
    display: block;
  }
}

.login-info {
  background-color: var(--primary-color);

  @include sm {
    display: none;
    visibility: hidden;
  }
}

.login-form-col {
  display: grid;
  place-items: center;

  min-height: 100vh;
}

.login-form-content {
  width: 460px;
  border-radius: 20px;
  padding: 2rem;

  @include sm {
    width: 100%;
  }
}

.login-form {
  margin-top: 30px;
}

.signup-text {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 0.9em;
}

.login-error-message {
  width: 100%;
  background-color: #ff0000;
  color: white;
  padding: 30px;
  margin-bottom: 20px;
}

.login-status-message {
  width: 100%;
  background-color: lightyellow;
  border: 1px solid wheat;
  padding: 30px;
  border-radius: 10px;
  margin-top: 20px;
}

.confirm {
  display: grid;
  place-items: center;

  min-height: 100vh;
}

.confirm-message {
  width: 300px;
  height: 200px;
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  text-align: center;
  border-radius: 10px;
  display: grid;
  place-items: center;
  @include font-body();
}
