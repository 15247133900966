form {
  @include font-body();
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.large-label {
  padding: 0;
  margin: 0 0 6px 0;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
}

input {
  padding: 12px;
  margin: 0 0 20px 0;
  background-color: white;
  font-family: inherit;
  border: 1px solid #ccc;
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

input[type="button"],
input[type="submit"] {
  appearance: button;
  backface-visibility: hidden;
  background-color: var(--primary-color);
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;

  font-family: $body-font -apple-system, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", Ubuntu, sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;

  &:hover {
    background-color: var(--font-color);
  }
}

.form-alert-danger {
  background-color: rgb(254, 191, 191);
  border: 1px solid lightcoral;
  border-radius: 10px;
  @include font-body();
  padding-left: 10px;
}

.form-alert-success {
  background-color: rgb(134, 232, 193);
  border: 1px solid rgb(69, 219, 99);
  border-radius: 10px;
  @include font-body();
  padding-left: 10px;
}

.add-colorscheme-form {
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
}
