html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #3b0fee;
  --button-face-color: #1d1d1d;
  --button-hover-color: #777777;
  --bg-color: #fdfdfd;
  --font-color: #1d1d1d;
  --footer-bg-color: #1d1d1d;
  --footer-font-color: #fdfdfd;
}

body {
  color: var(--font-color);
  background-color: var(--bg-color);

  margin: 0;
  padding: 0;

  overflow-x: hidden;
}

@mixin text-main() {
  color: var(--font-color);
}

.hide {
  display: none;
  visibility: hidden;
}

///*----------------------------------*\
//  #GLOBAL
//\*----------------------------------*/

// High-level base settings.
$base-font-size: 16px !default;
$base-line-height: 24px !default;
$base-text-color: #333 !default;
$base-background-color: #fff !default;

// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.
$base-spacing-unit: $base-line-height;
$base-spacing-unit-tiny: round(calc($base-spacing-unit / 4));
$base-spacing-unit-small: round(calc($base-spacing-unit / 2));
$base-spacing-unit-large: round($base-spacing-unit * 2);
$base-spacing-unit-huge: round($base-spacing-unit * 4);

@mixin clearfix($important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  &:after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }
}

// Simple truncation mixin to cut off text using an ellipsis after a certain
// width.
//
// .simple-usage {
//   @include truncate();
// }
//
// .detailed-usage {
//   @include truncate(<value>, important);
// }

@mixin truncate($limit: 100%, $important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  max-width: $limit $important;
  white-space: nowrap $important;
  overflow: hidden $important;
  text-overflow: ellipsis $important;
}
