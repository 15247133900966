.company-scheme {
  background-color: white;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 40px;
  @include font-body();
  border-radius: 20px;
}

.company-scheme-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.company-scheme-color {
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: bold;
  font-size: 0.8em;
  width: 120px;
  padding: 0;
}

.company-scheme-color-hex {
  width: 100%;
  height: 80px;
}

.company-scheme-name {
  margin: 8px 0 8px 8px;
}

.company-color-scheme-delete {
  width: 300px;
  margin-top: 20px;
}

.company-mini-schemes {
  background-color: white;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 40px;
  @include font-body();
  border-radius: 20px;
}

.company-mini-scheme-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}

.company-mini-scheme-color {
  flex: 0 0 50px;
  margin-bottom: 10px;
}

.company-mini-scheme-name {
  display: none;
  visibility: hidden;
}

.company-mini-scheme-color-hex {
  height: 50px;
  height: 50px;
}

.company-mini-scheme-color-link {
  margin: auto 0 auto 30px;
}

.color-scheme-details {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.color-scheme-details-color {
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  margin-bottom: 20px;
}

.color-scheme-details-color-hex {
  flex: 0 0 30%;
  width: 30%;
  height: 140px;
}

.color-scheme-details-info {
  margin: auto 0 auto 40px;
  @include font-body();
}

.company-list {
  margin-top: 48px;
  min-width: 800px;
}

.company-list-item {
  @include font-body();
  @include text-main();
  display: flex;
  padding: 12px;
  gap: 24px;
}

.company-list-item-col {
  width: 300px;
}
