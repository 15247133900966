.menu {
  background-color: var(--bg-color);
  //display: grid;
  //grid-template-rows: 300px 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-right: 1px solid #ddd;
  @include font-body();

  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;

  justify-content: space-between;
}

.navigation {
  background-color: var(--bg-color);
  padding: 24px;
}

.navigation ul {
  padding: 0;
  margin: 32px 0 0 0;
}

.navigation a {
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  text-align: -webkit-match-parent;
  @include font-body();
  font-weight: 500;
  font-size: 1.2em;
  list-style-type: none;
  border-radius: 12px;
  padding: 8px;
  margin: 0 0 10px 0;
  text-decoration: none;
  @include text-main();

  &:hover {
    background-color: rgb(229, 243, 248);
  }
}

.nav-item-logo {
  width: 32px;
  margin-right: 8px;
}

.navigation li a {
  text-decoration: none;
  @include text-main();
}

.menu-footer {
  margin: 20px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.menu-footer-status-label {
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 4px;
}

.menu-footer-status {
  margin-bottom: 20px;
}
