$heading-font: "Inter";
$body-font: "Inter";

@mixin font-header() {
  font-family: $heading-font;
  font-weight: 600;
}

@mixin font-header-slim() {
  font-family: $heading-font;
  font-weight: 400;
}

@mixin font-ui() {
  font-family: $body-font;
}

@mixin font-body() {
  font-family: $body-font, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

@mixin font-medium() {
  font-family: $body-font, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

@mixin font-bold() {
  font-family: $body-font, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

@mixin font-size($font-size, $line-height: "auto", $important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  font-size: $font-size $important;
  font-size: (calc($font-size / $base-font-size)) * 1rem $important;

  @if $line-height == "auto" {
    line-height: ceil(calc($font-size / $base-line-height)) *
      (calc($base-line-height / $font-size))
      $important;
  } @else {
    @if (
      type-of($line-height) ==
        number or
        $line-height ==
        inherit or
        $line-height ==
        normal
    ) {
      line-height: $line-height $important;
    } @else if ($line-height != none and $line-height != false) {
      @warn "D’oh! ‘#{$line-height}’ is not a valid value for `line-height`.";
    }
  }
}

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

// Preconfigure these settings before the @import of this file.

$heading-size-1: 48px !default;
$heading-size-2: 32px !default;
$heading-size-3: 26px !default;
$heading-size-4: 22px !default;
$heading-size-5: 16px !default;
$heading-size-6: 16px !default;

$mobile-heading-size-1: 36px !default;
$mobile-heading-size-2: 32px !default;
$mobile-heading-size-3: 24px !default;
$mobile-heading-size-4: 18px !default;
$mobile-heading-size-5: 14px !default;
$mobile-heading-size-6: 12px !default;

h1 {
  @include font-header();
  @include font-size($heading-size-1);
  letter-spacing: -0.04em;
  margin: 0;

  @include sm {
    font-size: $mobile-heading-size-1;
  }
}
h2 {
  @include font-header();
  @include font-size($heading-size-2);
  letter-spacing: -0.04em;
  margin: 0;
  @include sm {
    font-size: $mobile-heading-size-2;
  }
}
h3 {
  @include font-header();
  @include font-size($heading-size-3);
  letter-spacing: -0.01em;
  margin: 0;
  @include sm {
    font-size: $mobile-heading-size-3;
  }
}
h4 {
  @include font-header();
  @include font-size($heading-size-4);
  letter-spacing: -0.01em;
  margin: 0;
  @include sm {
    font-size: $mobile-heading-size-4;
  }
}
h5 {
  @include font-header();
  @include font-size($heading-size-5);
  letter-spacing: -0.01em;
  margin: 0;
  @include sm {
    font-size: $mobile-heading-size-5;
  }
}
h6 {
  @include font-header();
  @include font-size($heading-size-6);
  letter-spacing: -0.01em;
  margin: 0;
  @include sm {
    font-size: $mobile-heading-size-6;
  }
}
