.msg-screen {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.app {
  display: grid;
  grid-template-columns: 300px 1fr;

  min-height: 100vh;
}

.backend-screen {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 40px;
  margin-top: 74px;
}

.container-narrow {
  max-width: 800px;
}
